import React from 'react';

export default function PlayButton(props) {
  const { handleClick } = props;
  return (
    <div className="play-button-container">
      <div style={{ textAlign: 'center' }}>
        <button onClick={handleClick}>Play!</button>
      </div>
    </div>
  );
}
