import React from 'react';

export const getThemeIcons = (icons) => {
  const iconList = [];
  for (let x in icons) {
    let url = icons[x];
    let img = React.createElement('img', {
      src: url,
      className: 'icon',
    });
    iconList.push(img);
  }
  return iconList;
};
