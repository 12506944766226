import React, { useEffect, useState } from 'react';
import { useGameState } from '../../Contexts/GameStateContext';

import { ThemeProvider } from 'styled-components';
import { GlobalStyles } from '../../theme/GlobalStyle';
import { useTheme } from '../../theme/useTheme';

import { useParams } from 'react-router-dom';

import * as themes from '../../theme/schema.json';
import SaveBizInfo from './SaveBizInfo';

import Logo from '../Logo/Logo';
import UpperGameInterface from '../UpperGameInterface/UpperGameInterface';
import LowerGameInterface from '../LowerGameInterface/LowerGameInterface';
import Footer from '../Footer/Footer';
import dataFromFB from './dataFromFB';
import checkPlayed from './checkPlayed';
import { useIsFinished } from '../../Contexts/IsFinishedContext';
import { getFromLS } from '../../utils/storage';

export default function Game() {
  const { initGameState } = useGameState();
  const { bizID } = useParams();
  const { setTheme, theme, themeIcons, themeLoaded } = useTheme();
  const { setIsFinished } = useIsFinished();
  const [pulledData, setPulledData] = useState(null);

  useEffect(() => {
    setIsFinished(checkPlayed(bizID));
    const bizInfoFromLs = getFromLS('bizInfo');
    if (!bizInfoFromLs || bizInfoFromLs.slug != bizID) {
      dataFromFB(bizID).then((bizDoc) => {
        if (bizDoc) {
          setPulledData(bizDoc.data());
        }
      });
    }
  }, [bizID]);
  useEffect(() => {
    if (pulledData) {
      SaveBizInfo(pulledData.bizInfo, bizID);
      initGameState(pulledData.deals);
      setTheme(themes.default.data[pulledData.theme]);
    }
  }, [pulledData]);

  return (
    <>
      {themeLoaded && (
        <ThemeProvider theme={theme}>
          <GlobalStyles />

          <div className="main-game-container">
            <Logo />
            <UpperGameInterface themeIcons={themeIcons} />
            <LowerGameInterface />

            <Footer />
          </div>
        </ThemeProvider>
      )}
    </>
  );
}
