import React from 'react';
import './PopUp.css';

export default function PopUp(props) {
  return props.switch ? (
    <div className="popup">
      <div className="popup-inner">
        <b>{props.children}</b>
      </div>
    </div>
  ) : (
    ''
  );
}
