import React, { useContext, useState } from 'react';
import { setToLS, getFromLS } from '../utils/storage';
import determineWinner from './determineWinner';

const GameStateContext = React.createContext();

export function useGameState() {
  return useContext(GameStateContext);
}

export function GameStateProvider({ children }) {
  const [gameRunning, setGameRunning] = useState(false);

  function initGameState(deals) {
    if (!getFromLS('deal')) {
      const winningDeal = determineWinner(deals);
      setToLS('deal', winningDeal);
    }
  }

  const value = {
    gameRunning,
    setGameRunning,
    initGameState,
  };

  return (
    <GameStateContext.Provider value={value}>
      {children}
    </GameStateContext.Provider>
  );
}
