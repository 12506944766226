import React, { useState, useEffect, useRef } from 'react';
import WaitingGlow from '../Glow/WaitingGlow';
import FinalGlow from '../Glow/FinalGlow';
import { useGameState } from '../../Contexts/GameStateContext';
import { getFromLS } from '../../utils/storage';
import { useIsFinished } from '../../Contexts/IsFinishedContext';

export default function IconContainer(props) {
  const { containerId, icons } = props;
  const { gameRunning } = useGameState();
  const { setIsFinished, isFinished } = useIsFinished();
  const [timerDone, setTimerDone] = useState(false);
  const [isStopped, setIsStopped] = useState(false);
  const [imgIndex, setImgIndex] = useState(
    Math.floor(Math.random() * (icons.length - 1))
  );
  const imgChanger = useRef();
  const { isWin, finalIcon } = getFromLS('deal');
  let count = imgIndex;

  function updateImgIndex() {
    if (count < icons.length - 1) {
      count += 1;
    } else {
      count = 0;
    }
    setImgIndex(count);
  }

  function stopUpdate() {
    setIsStopped(true);
    clearInterval(imgChanger.current);
    containerId == 3 && setIsFinished(true);
  }

  useEffect(() => {
    if (timerDone) {
      let iconIndex = finalIcon;
      if (!isWin) {
        iconIndex = (iconIndex + containerId) % icons.length;
      }
      if (imgIndex == iconIndex) {
        stopUpdate();
      }
    }
  }, [timerDone, imgIndex]);

  useEffect(() => {
    if (gameRunning) {
      imgChanger.current = setInterval(function () {
        updateImgIndex();
      }, 75);
      setTimeout(function () {
        setTimerDone(true);
      }, 3000 + 850 * containerId);
    }
  }, [gameRunning]);

  const containerStyle = {
    position: 'relative',
    borderRadius: '.5em',
    width: '30vw',
    height: '30vw',
    padding: '1em',
    textAlign: 'center',
    overflow: 'hidden',
  };

  return (
    <div style={containerStyle} className="icon-container">
      <div style={{ width: '100%', height: '100%' }}>{icons[imgIndex]}</div>
      {isStopped && !isFinished && <WaitingGlow />}
      {<FinalGlow />}
    </div>
  );
}
