import React, { useState, useEffect } from 'react';
import { useTheme } from '../../theme/useTheme';

function WaitingGlow() {
  const { theme } = useTheme();
  const [colorObj, setColorObj] = useState({});

  useEffect(() => {
    theme &&
      setColorObj({
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.5,
        background: theme.colors.glow.waiting,
      });
  }, [theme]);

  return <div style={colorObj}></div>;
}

export default WaitingGlow;
