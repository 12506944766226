import React, { useState, useEffect } from 'react';
import { useTheme } from '../../theme/useTheme';
import { initGlowColor } from './initGlowColor';
import { useIsFinished } from '../../Contexts/IsFinishedContext';

function FinalGlow() {
  const { theme } = useTheme();
  const [colorObj, setColorObj] = useState({});
  const [color, setColor] = useState({ display: 'none' });
  const { isFinished } = useIsFinished();
  let isOn = true;

  function flash() {
    isOn
      ? setColor(colorObj)
      : setColor((oldstate) => ({
          ...oldstate,
          opacity: 0,
        }));
    isOn = !isOn;
  }

  useEffect(() => {
    if (theme) {
      setColorObj({
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        opacity: 0.5,
        background: initGlowColor(theme.colors.glow),
        transition: '.25s ease-out',
      });
    }
  }, [theme]);

  useEffect(() => {
    if (isFinished) {
      setInterval(() => {
        flash();
      }, 350);
    }
  }, [isFinished]);

  return <div style={color}></div>;
}

export default FinalGlow;
