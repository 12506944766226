import { getFromLS } from '../../utils/storage';

export function initGlowColor(glowColor) {
  const deal = getFromLS('deal');
  if (deal.isWin) {
    return glowColor.win;
  } else {
    return glowColor.loss;
  }
}
