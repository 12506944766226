import React from 'react';
import { useGameState } from '../../Contexts/GameStateContext';
import PlayButton from './PlayButton';

import LowerGameText from '../LowerGameText/LowerGameText';
import { useIsFinished } from '../../Contexts/IsFinishedContext';

export default function LowerGameInterface() {
  const { gameRunning, setGameRunning } = useGameState();
  const { isFinished } = useIsFinished();

  function handleClick() {
    setGameRunning(true);
  }

  return (
    <div
      style={{
        position: 'relative',
        height: '35vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        alignItems: 'center',
      }}
    >
      {!gameRunning && !isFinished && <PlayButton handleClick={handleClick} />}
      {isFinished && <LowerGameText />}
    </div>
  );
}
