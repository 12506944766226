import React, { useContext, useEffect, useState } from 'react';
import formatDate from '../utils/formatDate';
import { setToLS, getFromLS } from '../utils/storage';

const isFinishedContext = React.createContext();

export function useIsFinished() {
  return useContext(isFinishedContext);
}

export function IsFinishedProvider({ children }) {
  const [isFinished, setIsFinished] = useState();

  useEffect(() => {
    if (isFinished) {
      const { slug } = getFromLS('bizInfo');
      const finishedDataFromLS = getFromLS('finishedData');
      if (finishedDataFromLS) {
        let i = 0;
        while (i < finishedDataFromLS.length) {
          if (Object.values(finishedDataFromLS[i]).includes(slug)) {
            if (Object.values(finishedDataFromLS[i]).includes(formatDate())) {
              return;
            }
          }
          i++;
        }
      }
      const dataList = finishedDataFromLS ? [...finishedDataFromLS] : [];
      const isFinishedData = { slug: slug, date: formatDate() };
      dataList.push(isFinishedData);

      setToLS('finishedData', dataList);
    }
  }, [isFinished]);

  const value = {
    isFinished,
    setIsFinished,
  };

  return (
    <isFinishedContext.Provider value={value}>
      {children}
    </isFinishedContext.Provider>
  );
}
