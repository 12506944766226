import React from 'react';
import { getFromLS } from '../../utils/storage';

export default function Logo() {
  return (
    <div
      style={{
        height: '15vh',
        textAlign: 'center',
        padding: '.25em',
        paddingTop: '1em',
      }}
    >
      <img
        style={{ maxWidth: '100%', maxHeight: '100%' }}
        src={getFromLS('bizInfo').logo}
      ></img>
    </div>
  );
}
