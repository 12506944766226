import React from 'react';
import { getFromLS } from '../../utils/storage';
import LosingText from '../LowerGameInterface/LosingText';
import WinningText from '../LowerGameInterface/WinningText';
import SocialBanner from '../SocialBanner/SocialBanner';

export default function LowerGameText() {
  const deal = getFromLS('deal');

  return (
    <>
      {deal.isWin ? <WinningText dealText={deal.text} /> : <LosingText />}
      <SocialBanner />
    </>
  );
}
