import React from 'react';
import IconContainer from '../IconContainer/IconContainer';

export default function UpperGameInterface(props) {
  const { themeIcons } = props;
  return (
    <div className="upper-game-container">
      <div style={{ display: 'flex', width: '100%' }}>
        <IconContainer containerId={1} icons={themeIcons} />
        <IconContainer containerId={2} icons={themeIcons} />
        <IconContainer containerId={3} icons={themeIcons} />
      </div>
    </div>
  );
}
