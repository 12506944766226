import React from 'react';
import { getFromLS } from '../../utils/storage';
import ImportImage from '../../utils/ImportImage';

export default function SocialBanner() {
  const bizInfo = getFromLS('bizInfo');
  const { colors } = getFromLS('theme');
  const socials = bizInfo.socials;

  const socialIcons = {
    facebook: 'fb-' + colors.social,
    instagram: 'ig-' + colors.social,
  };

  function socialClick(e) {
    window.location.href = e;
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {Object.keys(socials).map((data, i) => (
        <img
          onClick={() => {
            socialClick(socials[data]);
          }}
          className="social-icon"
          src={`/socialIcons/${socialIcons[data]}.webp`}
        />
      ))}
    </div>
  );
}
