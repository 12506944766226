import { createGlobalStyle } from 'styled-components';
import ImportBodyImage from '../utils/ImportImage';
export const GlobalStyles = createGlobalStyle`
    * {
        margin:0;
        box-sizing:border-box;
    }
    h1{
      font-size:7vw; 
      line-height:1em;
      color: ${({ theme }) => theme.colors.h1};
      width:95%;
      font-weight:normal;
      margin-left:auto;
      margin-right:auto;
    }
    h3{
      color:white;
    }

  body {
    background: ${({ theme }) =>
      theme.colors.body.includes('.')
        ? `url(${ImportBodyImage(theme.colors.body)})`
        : theme.colors.body};
    background-size: cover;
    color: ${({ theme }) => theme.colors.text};
    font-family: ${({ theme }) => theme.font};
  }
  .main-game-container{
    position:relative;
    width: 100vw;
    max-height: 100vh;
    display:flex;
    flex-direction:column
  }

  button {
    border: 0;
    display: inline-block;
    padding: 12px 24px;
    font-size: 1em;
    border-radius: 4px;
    cursor: pointer;
    background: ${({ theme }) => theme.colors.button.background};
    color: #FFFFFF;
    font-family: ${({ theme }) => theme.font};
    outline: ${({ theme }) =>
      theme.colors.button.outline
        ? `3px ${theme.colors.button.outline} solid`
        : 'none'} ;
  }

  .icon-container{
    background: ${({ theme }) => theme.colors.iconContainer.background};
    margin:auto;
    box-shadow: inset 6px 6px ${({ theme }) =>
      theme.colors.iconContainer.shadow};
  }
  .icon{
    max-width:100%;
    max-height:100%;
    -webkit-filter: drop-shadow(6px 6px ${({ theme }) =>
      theme.colors.iconContainer.shadow});
  }
  .footer{
    color:${({ theme }) => theme.colors.footerText};
    display: flex;
    flex-direction: column;
    background: ${({ theme }) => theme.colors.footer};
    text-align:center;
    padding:1em;
    justify-content:space-around;
    height:10vh;
    width:100%;
    position:fixed;
    bottom:0vh;
  }
  .upper-game-container{
      height: 30vh;
      display: flex;
      justify-content: space-around;
      flex-direction: column;
    }
   .play-button-container {
      width:100%;
      position: absolute;
      top: 40%;
      transform: translate(0,-50%)
    }
    .social-icon{
      height:2em;
      aspect-ratio:1/1;
    }
    .fb-logo{
      background: ${({ theme }) =>
        `url(${ImportBodyImage(
          'socialicons/ig' + theme.colors.social + '.webp'
        )})`}}

    .ig-logo{
      background-image: ${({ theme }) =>
        `url(${ImportBodyImage(
          'socialIcons/ig-' + theme.colors.social + '.webp'
        )})`}}

    .popup-inner {
      position: relative;
      padding: 1em;
      width: 80%;
      border-radius: .5em;
      background:${({ theme }) => theme.colors.footerText};
      outline: 2px  ${({ theme }) => theme.colors.footer} solid;
      color: ${({ theme }) => theme.colors.footer};
    }
  }
`;
