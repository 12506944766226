import { useEffect, useState } from 'react';
import { getFromLS, setToLS } from '../utils/storage';
import { getThemeIcons } from './getThemeIcons';

export const useTheme = () => {
  const [theme, setTheme] = useState(getFromLS('theme'));
  const [themeLoaded, setThemeLoaded] = useState(false);
  const [themeIcons, setThemeIcons] = useState(null);

  useEffect(() => {
    if (!themeLoaded && theme) {
      setThemeIcons(getThemeIcons(theme.icons));
      setToLS('theme', theme);
      setThemeLoaded(true);
    }
  }, [theme]);

  return { setTheme, theme, themeLoaded, themeIcons };
};
