import React from 'react';

export default function LosingText() {
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>
        Sorry, you didn't win.
        <br />
      </h1>
      <h3 style={{ marginTop: '.25em' }}>
        Come visit us and try again tomorrow!
      </h3>
    </div>
  );
}
