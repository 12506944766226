const ImportBodyImage = (name) => {
  try {
    const image = require(`../images/${name}`);
    if (!image) return null;
    return image;
  } catch (error) {
    return null;
  }
};

export default ImportBodyImage;
