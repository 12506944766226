import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Game from './Components/Game/Game';
import Test from './Components/Test/Test';
import { GameStateProvider } from './Contexts/GameStateContext';
import { IsFinishedProvider } from './Contexts/IsFinishedContext';

function App() {
  const subdomain =
    window.location.host.split('.')[0] !== 'www'
      ? window.location.host.split('.')[0]
      : window.location.host.split('.')[1];

  return (
    <Router>
      <GameStateProvider>
        <IsFinishedProvider>
          <Routes>
            <Route path="/:bizID" element={<Game />}></Route>
            <Route path="/" element={<Test />}></Route>
          </Routes>
        </IsFinishedProvider>
      </GameStateProvider>
    </Router>
  );
}

export default App;
