function createFreqList(deals) {
  const freqList = [];
  for (let deal in deals) {
    const dealFreq = deals[deal].frequency;
    freqList.push(parseFloat(dealFreq));
  }
  return freqList;
}
function getChosenDealIndex(drawnNumber, freqList) {
  let winnerIndex = null;
  freqList.forEach((e, i) => {
    //if there's no winner index, check if the drawn num is <= current pos in 'freqList'
    //<= works because 'freqList' is always in asc order
    if (winnerIndex == null && drawnNumber <= freqList[i]) {
      winnerIndex = i + 1;
    }
  });
  return winnerIndex;
}

function addIconToDeal(deal) {
  deal['finalIcon'] = Math.floor(Math.random() * 5);
  return deal;
}

export default function determineWinner(deals) {
  const freqList = createFreqList(deals);
  //add the frequency of odds, creates "buckets" for randomly drawn number to fall in
  freqList.forEach((e, i) => (freqList[i] = i == 0 ? e : e + freqList[i - 1]));
  let drawnNumber = Math.random();
  //return winner's index to be used as key in 'deals' object
  const chosenDealIndex = getChosenDealIndex(drawnNumber, freqList);
  const dealwithIcon = addIconToDeal(deals[chosenDealIndex]);

  return dealwithIcon;
}
