import React, { useState } from 'react';
import { getFromLS } from '../../utils/storage';
import PopUp from '../PopUp/PopUp';

export default function Footer() {
  const [popUpSwitch, setPopUpSwitch] = useState(false);
  const [popUpText, setPopUpText] = useState();
  const bizInfo = getFromLS('bizInfo');

  function copyText(e) {
    navigator.clipboard.writeText(e.target.innerText);
    setPopUpText(e.target.id);
    setPopUpSwitch(true);
    const timer = setTimeout(() => {
      setPopUpSwitch(false);
    }, 2500);
  }

  return (
    <div className="footer">
      <div id={'Address'} onClick={copyText}>
        {bizInfo.address}
      </div>
      <div id={'Phone number'} onClick={copyText}>
        {bizInfo.phone}
      </div>
      <PopUp switch={popUpSwitch}>{popUpText} copied</PopUp>
    </div>
  );
}
