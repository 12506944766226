import React from 'react';

export default function WinningText(props) {
  const { dealText } = props;
  return (
    <div style={{ textAlign: 'center' }}>
      <h1>
        You won {dealText}!<br />
      </h1>
      <h3 style={{ marginTop: '.25em' }}>
        Show this screen to an employee to recieve your discount
      </h3>
    </div>
  );
}
