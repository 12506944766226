import formatDate from '../../utils/formatDate';
import { getFromLS, setToLS } from '../../utils/storage';

export default function checkPlayed(slug) {
  const finishedData = getFromLS('finishedData');
  let isPlayed = false;
  if (finishedData) {
    let i = 0;
    while (i < finishedData.length) {
      if (Object.values(finishedData[i]).includes(slug)) {
        if (Object.values(finishedData[i]).includes(formatDate())) {
          isPlayed = true;
        } else {
          finishedData.splice(i, 1);
          setToLS('finishedData', finishedData);
        }
      }
      i++;
    }
    return isPlayed;
  } else {
    return isPlayed;
  }
}
